import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { initializeFirebase } from './push-notification';
import './index.css';

(async () => {
    //const publishableKey = "pk_test_51Jyn34EDt4ocA2aJNzbmnfUY5M6b2VL0x5fWb6AxfMv4FVYVLEcDJxgSqt9ZEhTlpsEixWoEKWQR75QVFQcmhSZg00SM4uWIDS";
    const publishableKey = "pk_live_51Jyn34EDt4ocA2aJ80X6uiM9IwhO13OsdGVqPqUSYZniaGqDjDryxBSBNrwi4pw26pGRhZWn4OOtd2rCgUTKXxqk00e4QxWC6C";
    const stripePromise = loadStripe(publishableKey);
    ReactDOM.render(
        <Elements stripe={stripePromise}>
            <App />
        </Elements>,
        
        document.getElementById('root')
    );
})()

initializeFirebase();