import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#fff8e5",
      main: "#CC802F",
      medium: "#f4d395",
      custom: "#EFA115",
    },
    secondary: {
      light: "#f6edfd",
      main: "#170584",
      dark: "#c3c2ee",
    },
    danger: {
      main: "#af1515",
    },
    success: {
      main: "#23ad27",
    },
    black: {
      light: "#707070",
      main: "#000000",
      medium: "#7e84a3",
    },
    other: {
      light: "#6e8ca0",
      main: "#ccbb4e",
      medium: "#c8d1d3",
    },
    mixes: {
      splash:
        "linear-gradient(180deg, rgba(254,247,227,1) 0%, rgba(236,175,39,1) 100%)",
      pribar:
        "linear-gradient(180deg, rgba(254,195,92,1) 0%, rgba(244,210,146,1) 100%)",
      secbar:
        "linear-gradient(180deg, rgba(253,253,254,1) 0%, rgba(129,110,201,1) 100%)",
    },
  },
  typography: {
    fontFamily: "'Europa', 'Segoe UI', 'sans-serif'",
    h1: {
      fontSize: 34,
      fontWeight: 600,
      letterSpacing: 1.5,
      textShadow: "0 0 1px",
    },
    h2: {
      fontSize: 30,
      fontWeight: 600,
    },
    h3: {
      fontSize: 28,
      fontWeight: 600,
    },
    h4: {
      fontSize: 26,
      fontWeight: 600,
      letterSpacing: 1.5,
      "& + .MuiTypography-subtitle1, & + .MuiTypography-subtitle1 + .MuiTypography-subtitle1":
        {
          paddingBottom: 30,
          "& + .MuiTypography-subtitle2[data-error]": {
            position: "relative",
            marginTop: -30,
            lineHeight: 1.3,
          },
        },
      "& + .MuiTypography-subtitle1 + .MuiTypography-subtitle1": {
        marginTop: -30,
      },
    },
    h5: {
      fontSize: 24,
      fontWeight: 400,
    },
    h6: {
      fontSize: 22,
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: 18,
      lineHeight: 1.3,
      fontWeight: 600,
      letterSpacing: 0.2,
    },
    subtitle2: {
      fontSize: 16,
      letterSpacing: "1.5px",
    },
    body1: {
      fontSize: 14,
      lineHeight: 1.6,
      letterSpacing: 0,
    },
    body2: {
      fontSize: 12,
    },
  },
  overrides: {
    MuiMenu: {
      list: {
        "& section": {
          "& .MuiListItem-root": {
            padding: "0 10px",
          },
        },
      },
    },
    MuiButton: {
      root: {
        width: "100%",
        borderRadius: 5,
        fontWeight: 300,
        letterSpacing: "1.25px",
        textTransform: "capitalize",
        "&:not([data-fullwidth])": {
          width: "auto",
          minWidth: 130,
        },
        "&.success": {
          backgroundColor: "#23ad27",
          color: "#fff",
          "&:hover,&:focus": {
            backgroundColor: "#1a7b1d",
          },
        },
        "&.danger": {
          backgroundColor: "#af1515",
          color: "#fff",
          "&:hover,&:focus": {
            backgroundColor: "#8c1111",
          },
        },
        "&.warning": {
          backgroundColor: "#EFA115",
          color: "#fff",
          "&:hover,&:focus": {
            backgroundColor: "#CC802F",
          },
          "&.MuiButton-outlined": {
            backgroundColor: "transparent",
            border: "1px solid #EFA115",
            color: "#EFA115",
            "&:hover,&:focus": {
              backgroundColor: "#ffe9c2"
            },
          },
        },
        "&.MuiButton-containedSizeLarge:hover": {
          backgroundColor: "#0f0452",
        },
        "& .MuiButton-label": {
          fontSize: 18,
          textTransform: "none",
        },
      },
      containedPrimary: {
        color: "#170584",
        border: "1px solid",
        backgroundColor: "#fff",
        "&:hover": {
          backgroundColor: "#fff !important",
        },
      },
      containedSecondary: {
        backgroundColor: "#170584",
      },
      containedSizeLarge: {
        padding: 10,
      },
    },
    MuiIconButton: {
      root: {
        "@media (min-width: 1024px)": {
          "&:hover svg": {
            opacity: 0.8,
          },
        },
      },
    },
    MuiAlert: {
      root: {
        lineHeight: 1.7,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "0.6rem",
        color: "#fff",
        backgroundColor: "#000",
      },
      arrow: {
        color: "#000",
      },
    },
    MuiTextField: {
      root: {
        marginTop: 16,
        marginBottom: "16px!important",
        position: "relative",
        "& + h6": {
          height: 0,
          position: "relative",
          top: -13,
        },
        "& .MuiInputBase-input:not(textarea), & .MuiInputBase-multiline": {
          padding: 16,
          border: "1px solid #170584",
          borderRadius:6
        },
        "& .MuiInputLabel-outlined": {
          fontSize: 20,
          transform: "translate(0, -17px) scale(0.75)",
        },
        "& .MuiFormHelperText-contained": {
          position: "absolute",
          top: "100%",
          width: "100%",
          margin: 0,
          fontSize: 16,
          fontWeight: 600,
          color: "#af1515",
        },
        "& legend > span": {
          display: "none",
        },
        "& .Mui-error": {
          color: "#C41313",
          lineHeight: 1.2,
        },
        "& .MuiFormLabel-asterisk": {
          color: "#C41313",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          display: "none",
        },
      },
    },
    MuiSelect: {
      root: {
        "& ~ .MuiOutlinedInput-notchedOutline": {
          borderColor: "#170584",
          borderRadius: 0,
        },
      },
    },
    MuiTab: {
      root: {
        "& .MuiTab-wrapper": {
          color: "initial",
          fontWeight: 600,
        },
        "&:not(.Mui-selected) .MuiTab-wrapper": {
          opacity: 0.5,
        },
      },
    },
    MuiRadio: {
      root: {
        "& input + div": {
          backgroundColor: "#ffffff",
          borderRadius: "100%",
          "& .MuiSvgIcon-root": {
            fill: "#170584",
          },
        },
      },
    },
    MuiFormControl: {
      root: {
        flex: 1,
        "& .MuiFormControlLabel-root": {
          margin: 0,
          "& .MuiCheckbox-root": {
            position: "absolute",
            right: -10,
          },
        },
        "& .MuiRadio-root + .MuiTypography-root, .MuiCheckbox-root + .MuiTypography-root":
          {
            fontSize: 16,
            padding: "15px 0",
          },
        '& .MuiFormGroup-root[role="radiogroup"] .MuiFormControlLabel-root': {
          marginLeft: -10,
        },
      },
    },
    MuiTypography: {
      root: {
        "&[data-error]": {
          color: "#C41313",
          letterSpacing: 0,
          lineHeight: 1.3,
          fontWeight: 600,
        },
      },
    },
    MuiAccordion: {
      root: {
        "&:before": {
          display: "none",
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        "&.Mui-expanded": {
          minHeight: 0,
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
          margin: "12px 0",
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        '& .MuiFormGroup-root[role="radiogroup"] .MuiFormControlLabel-root': {
          marginLeft: "0!important",
        },
      },
    },
  },
});

export default theme;
