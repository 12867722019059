import AuthService from "../services/authService";

const MESSAGE_KEY = "__MESSAGECOUNT";
const Brands_key = "__Brands"
const CommonService = {
    serverUrl() {
        //return "https://localhost:44385/";
        //return "https://buadmin.kwworks.net/";
        return "https://braidup.azurewebsites.net/";
    },
    yearPriceId() {
        //return 'price_1K1eAzEDt4ocA2aJD71au3CF';
        return 'price_1K5D2xEDt4ocA2aJdA2cyewM';
    },
    monthPriceId() {
        //return 'price_1K1G9PEDt4ocA2aJeuYQKfFl';
        return 'price_1K5D6ZEDt4ocA2aJ26svLG5K';
    },
    ExecuteGet(request, callback) {
        fetch(CommonService.serverUrl() + 'api/common/' + request.Url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(res => {
                if (callback) {
                    callback(toCamel(res));
                }
            }).catch((res) => {
                res = CommonService.toCamel(res);
                if (res.message === "Unexpected token < in JSON at position 0") {
                    window.location.href = "/access/signup";
                }
                if (callback) {
                    callback(res);
                }
            });
    },
    ExecuteAutorizeGet(request, callback) {
        fetch(CommonService.serverUrl() + 'api/common/' + request.Url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthService.getAuthToken()
            }
        }).then(res => res.json())
            .then(res => {
                if (callback) {
                    callback(toCamel(res));
                }
            }).catch((res) => {
                res = CommonService.toCamel(res);
                if (res.message === "Unexpected token < in JSON at position 0") {
                    window.location.href = "/access/signup";
                }
                if (callback) {
                    callback(res);
                }
            });
    },
    PaymentExecuteGet(request, callback) {
        fetch(CommonService.serverUrl() + 'api/payment/' + request.Url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(res => {
                if (callback) {
                    callback(toCamel(res));
                }
            }).catch((res) => {
                res = CommonService.toCamel(res);
                if (res.message === "Unexpected token < in JSON at position 0") {
                    window.location.href = "/access/signup";
                }
                if (callback) {
                    callback(res);
                }
            });
    },
    PaymentAuthExecuteGet(request, callback) {
        fetch(CommonService.serverUrl() + 'api/payment/' + request.Url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthService.getAuthToken()
            }
        }).then(res => res.json())
            .then(res => {
                if (callback) {
                    callback(toCamel(res));
                }
            }).catch((res) => {
                res = CommonService.toCamel(res);
                if (res.message === "Unexpected token < in JSON at position 0") {
                    window.location.href = "/access/signup";
                }
                if (callback) {
                    callback(res);
                }
            });
    },
    ExecutePost(request, body, callback) {
        fetch(CommonService.serverUrl() + 'api/common/' + request.Url, {
            method: "Post",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then(res => res.json())
            .then(res => {
                res = CommonService.toCamel(res);
                if (callback) {
                    callback(res);
                }
            }).catch((res) => {
                res = CommonService.toCamel(res);
                if (res.message === "Unexpected token < in JSON at position 0") {
                    window.location.href = "/access/signup";
                }
            });
    },
    PaymentExecutePost(request, body, callback) {
        fetch(CommonService.serverUrl() + 'api/payment/' + request.Url, {
            method: "Post",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then(res => res.json())
            .then(res => {
                res = CommonService.toCamel(res);
                if (callback) {
                    callback(res);
                }
            }).catch((res) => {
                res = CommonService.toCamel(res);
                if (res.message === "Unexpected token < in JSON at position 0") {
                    window.location.href = "/access/signup";
                }
            });
    },
    setMessageCount(callback) {
        if (AuthService.getBraiderId() != null) {
            var filters = "?userName=" + AuthService.getUserName() + "&braiderId=" + (AuthService.getBraiderId() == null ? undefined : AuthService.getBraiderId());
            var seekerId = AuthService.getSeekerId();
            if (seekerId != null) {
                filters = filters + "&seekerId=" + seekerId;
            }
            this.ExecuteGet({ Url: "getUnreadMessagesCount" + filters }, (res) => {
                localStorage.setItem(MESSAGE_KEY, res.count || 0);
                if (callback) {
                    callback(res.count || 0);
                }
            });
        } else {
            this.ExecuteGet({ Url: "getUnreadMessagesCount?seekerId=" + AuthService.getSeekerId() + "&userName=" + AuthService.getUserName() }, (res) => {
                localStorage.setItem(MESSAGE_KEY, res.count || 0);

                if (callback) {
                    callback(res.count || 0);
                }
            });
        }


    },
    checkUserStatus(callback) {
        var filters = "?";
        if(AuthService.getBraiderId() != null){
            filters = filters+"braiderId=" + AuthService.getBraiderId()+"&";
        }
        var seekerId = AuthService.getSeekerId();
        if (seekerId != null) {
            filters = filters + "seekerId=" + seekerId;
        }
        this.ExecuteGet({ Url: "GetUserStatus" + filters }, (res) => {
            callback(res);
        });
    },

    setBrands(callback) {
        this.ExecuteGet({ Url: "getbrands" }, (res) => {
            localStorage.setItem(Brands_key, JSON.stringify(res) || []);
            if (callback) {
                callback(res || []);
            }
        });
    },
    getBrands() {
        let res = localStorage.getItem(Brands_key) || [];
        return res;
    },
    getMessageCount() {
        let count = localStorage.getItem(MESSAGE_KEY) || 0
        return count;
    },
    toCamel(req) {
        return toCamel(req);
    }

};
function toCamel(o) {
    var newO, origKey, newKey, value
    if (o instanceof Array) {
        return o.map(function (value) {
            if (typeof value === "object") {
                value = toCamel(value)
            }
            return value
        })
    } else {
        newO = {}
        for (origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
                value = o[origKey]
                if (value instanceof Array || (value !== null && value.constructor === Object)) {
                    value = toCamel(value)
                }
                newO[newKey] = value
            }
        }
    }
    return newO
};
export default CommonService;