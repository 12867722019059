import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import AuthService from "./services/authService";
import CommonService from './services/CommonService';
var app = null;
export const initializeFirebase = () => {
    const firebaseConfig = {
        apiKey: "AIzaSyAovpth3Un-CJagsqUroUUIt7EPoYerKH8",
        authDomain: "genfini-braidup.firebaseapp.com",
        projectId: "genfini-braidup",
        storageBucket: "genfini-braidup.appspot.com",
        messagingSenderId: "607400121803",
        appId: "1:607400121803:web:c1e0b3c45044a68481d24e",
        
      };
    app = initializeApp(firebaseConfig);

    console.log('Initialization Done')
}

export const askForPermissionToReceiveNotifications = async (fromSignUp) => {
    if(localStorage.getItem('__OSNAME') !== 'macos' && localStorage.getItem('__OSNAME') !== 'ios'){
        requestPermission(fromSignUp);
    }
}

function getMessagingToken(fromSignUp) {
    const messaging = getMessaging(app);
    getToken(messaging, { vapidKey: 'BF18DmPvBzrSOdpA-Keu-JqPaxo2hhCwLfllxTWr1KfnXA6MgGom5CJbbvI25vV-Q69iONNSFR3nEO07QqRljd0' }).then((currentToken) => {
        if (currentToken) {
            // Send the token to your server and update the UI if necessary
            var url = "setfirebasetoken?userId=" + AuthService.getUserId() + "&token=" + currentToken;
            if (fromSignUp === true) {
                url = url + "&sendnotification=true&fromSignUp=true"
            } else {
                url = url + "&sendnotification=true&fromSignUp=false"
            }
            CommonService.ExecuteGet({ Url: url }, (res) => {
                if (res.isSuccess) {
                    console.log('Token Sent Successfully:', currentToken)
                    AuthService.setNoticationField(true);
                }
                else {
                    console.log(res.message)
                }
            });
        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });
    onMessage(messaging, payload => {

        // Push Notifications even if App is in front
        {/*
         const { title, ...options } = payload.notification;
            navigator.serviceWorker.ready.then(registration => {
            registration.showNotification(title, options);
        });
        */}
        if (payload.data !== undefined) {
            // Store ID of current notification
            localStorage.setItem('_CurrentNotificationId', payload.data["gcm.notification.messageId"]);

            // Click on button to get the notifications from localstorage
            document.getElementById("updateNotifications").click();
        }


    });
}

function requestPermission(fromSignUp) {
    console.log('Requesting Notification Permission');
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification Permission Granted.');
            if(fromSignUp){
                
            }
            getMessagingToken(fromSignUp);
            // TODO(developer): Retrieve a registration token for use with FCM.
            // In many cases once an app has been granted notification permission,
            // it should update its UI reflecting this.
            //resetUI();
        } else {
            console.log('Unable to get permission to notify.');
        }
    });
}
